import {http, httpNewApi, httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {
  ICheckOrderPhone,
  ICODList,
  IOrder,
  IOrderCancelRequest,
  IOrderDriverCallbackRequest,
  IOrderError,
  IOrderSendBillRequest,
  IPaymentInfo,
  IPaymentQRRequest,
  IRequestError, OpinionRequest, OrderDocumentRequest,
  PricePostData,
  TimeTableRequest,
  VZResponse
} from '~/types/api';
import {InventoryItem, LoadingManifest, orderRecordData, ResponseMultiPickup} from '~/types/order/order';
import {
  AwatingList,
  CargoCategory,
  IPrice,
  Location, Meta,
  MiniCalcPrice,
  OrderMarketplace,
  PickupPoint
} from '~/types/types';
import {deepCopyObject} from '~/common/functions';
import {useMemoize} from '@vueuse/core';
import {RawTimeTableV3} from '~/types/typesV3';
import type {LngLatBounds} from '@yandex/ymaps3-types';
import {SpecificLoadingApi} from '~/types/order/specific-loading';

export function getOrder(body: any) {
  return useVzFetch(
      `${http}/order/get-order`,
      { method: 'post', body }
  ) as Promise<orderRecordData>
}

export function getOrderHistory(orderGuid: string) {
  return useVzFetch(
      `${httpApiV3}/personal/order/history/${orderGuid}`
  ) as Promise<any>
}

export function getWidgetPrice(body: any) {
  return $fetch<MiniCalcPrice>(`https://vozovoz.ru/widgets/calculator-price/`,{ method: 'post', body })
}

export function getPrice(body: PricePostData, signal: AbortSignal|undefined = undefined) {
  return useVzFetch<IPrice>(`${http}/price/get`,{ method: 'post', body, signal })
}

export const getPriceMultiPickup = useMemoize((params: any) => {
  // noCache=1
  return useVzFetch(`${httpApiV3}/order/price/mini/multi-pickup?noCache=1`, {method: 'get', params}) as Promise<ResponseMultiPickup>
})

export function getOrderCalculation(id: string) {
  return useVzFetch(`${httpApiV3}/order/calculation/${id}`) as Promise<orderRecordData>
}

export function orderSaveCalc(orderData: orderRecordData) {
  return useVzFetch(`${httpApiV3}/order/calculation`,
      { method: 'post', body: { orderData } }
  ) as Promise<{ hash: string, origin: string }>
}

export function orderSendCalc(body: { email: string, link: string, hash?: string }) {
  return useVzFetch(`${httpApiV3}/order/calculation/send`,
      { method: 'post', body }
  ) as Promise<{ error?: IRequestError } | any>
}

export async function orderCreate(payload: any, test = false) {
  const result: { id?: string, number?: string, warnings?: any, reject?: { message: string } } = await useVzFetch(`${http}/order/create`,
      { method: 'post', body: { ...payload, test } }
  )
  if (result.reject) {
    useNuxtApp().$showToast({
      title: 'Возникла ошибка',
      text: result.reject?.message || '',
      variant: 'error'
    })
  }
  return result
}

export function getOrderPrice(body: any, manual = false, signal: AbortSignal | undefined = undefined) {
  return useVzFetch(`${httpNewApi}/order/get-price`,
      { method: 'post', body, manual, signal }
  ) as Promise<any>
}

export function orderCancelRequest(requestData: IOrderCancelRequest) {
  return useVzFetch(
      `${httpNewApi}/order/cancel`,
      { method: 'post', body: requestData }
  ) as Promise<{error?: IRequestError}>
}

export const getOrderMarketplace = useMemoize(() => {
  return useVzFetch(
    `${httpApiV3}/order/marketplace`,
    { method: 'get' }
  ) as Promise<OrderMarketplace[]>
})

export function orderSendBillToEmail(requestData: IOrderSendBillRequest) {
  return useVzFetch(
      `${httpNewApi}/order/favor/send-bill-to-email`,
      { method: 'post', body: requestData }
  ) as Promise<{message: string, error?: IRequestError}>
}

export function orderSetCargoLock(body: { orderGuid: string, lock: boolean, lockMode?: string }) {
  return useVzFetch(
      `${httpNewApi}/order/set-cargo-lock`,
      { method: 'post', body }
  ) as Promise<{message: string, error?: IRequestError}>
}

export const getCargoCategoryList = useMemoize(() => {
  return useVzFetch(
      `${httpNewApi}/order/cargo-category/get-list`,
      { method: 'post' }
  ) as Promise<{list: CargoCategory[], message: any}>
})

export function orderRequestDriverCallback(body: IOrderDriverCallbackRequest) {
  return useVzFetch(
      `${httpNewApi}/order/favor/request-driver-callback`,
      { method: 'post', body }
  ) as Promise<{result: string, error?: IRequestError}>
}

export async function getTimeTable(body: TimeTableRequest, signal: AbortSignal | undefined = undefined, manual = false):
    Promise<VZResponse<RawTimeTableV3>> {
  try {
    return await useVzFetch<VZResponse<RawTimeTableV3>>(
        `${httpApiV3}/order/timetable`,
        {method: 'get', params: deepCopyObject(body), signal, manual}
    )
  } catch (e: any) {
    return e.response?._data?.message
        ? {error: {message: e.response?._data?.message}}
        : e.response?._data
  }
}

export function getWaitingData() {
  return useVzFetch(
      `${httpNewApi}/order/need-loading-waiting-time/get`,
      { method: 'post' }
  ) as Promise<AwatingList>
}

export const getOrderFloorByZone = useMemoize((zone: string) => {
  return useVzFetch(
      `${http}/location/get-floor-by-zone`,
      { method: 'post', body: { zone } }
  ) as Promise<{ floor: string[] }>
})

export const getOrderSpecificLoadingList = useMemoize((zoneGuid?: string) => {
  return useVzFetch(
      `${httpNewApi}/order/specific-loading/get-list`,
      { method: 'post', body: { zoneGuid } }
  ) as Promise<string[]>
})

export const getAllOrderSpecificLoadingList = useMemoize(() => {
  return useVzFetch<SpecificLoadingApi[]>(
      `${httpNewApi}/order/specific-loading/get-list`,
      { method: 'post'}
  )
})

export const getOrderSpecificLoadingCategoryList = useMemoize(() => {
  return useVzFetch(
      `${httpNewApi}/order/specific-loading/get-category-list`,
      { method: 'post' }
  ) as Promise<any>
})

export function checkPhoneByCode(body: {tel: string, code?: string}) {
  return useVzFetch(
      `${http}/order/check-phone`,
      { method: 'post', body }
  ) as Promise<{success: any, time: any, ts: any, error: { desc: string }}>
}

export function opinionAdd(body: OpinionRequest): Promise<VZResponse<{}>> {
  return useVzFetch(
      `${httpNewApi}/order/opinion/add`,
      // разрешаем передачу куков
      { method: 'post', body, credentials: 'include' }
  )
}

export const CODListImportExcel = (body: FormData): Promise<Pick<ICODList, 'shop'|'list'> & { error?: IRequestError }> => {
  return useVzFetch(`${httpNewApi}/order/cod-list/import-excel`, {
    method: 'POST',
    body
  })
}

export const updateCODList = (body: ICODList) => {
  return useVzFetch(`${httpNewApi}/order/cod-list/update`, {
    method: 'POST',
    body
  }) as any
}

export const checkOrderPhone = (body: ICheckOrderPhone): Promise<IOrder & IOrderError> => {
  return useVzFetch(`${http}/order/mini`, {
    method: 'POST',
    body
  })
}

export const getOrderPaymentInfo = (body: { number: string }): Promise<IPaymentInfo> => {
  return useVzFetch(`${http}/payment/get`, {
    method: 'POST',
    body
  })
}

export const getOrderPaymentQR = (body: IPaymentQRRequest): Promise<{ guid: string, href: string, image: string, error?: { message: string, title: string } }> => {
  return useVzFetch(`${http}/payment/get-qr-code`, {
    method: 'POST',
    body
  })
}

export const orderPaymentQRCheck = (body: { guid: string }): Promise<{ message: string, error?: {message: string, title: string} }> => {
  return useVzFetch(`${http}/payment/check-qr-code`, {
    method: 'POST',
    body
  })
}

export const getPaymentLinkRedirection = (action: any, body: any) => {
  return useVzFetch<{url?: string, htmlForm: any}>(`${httpNewApi}${action}`, {
    method: 'POST',
    body
  })
}

export const orderBonusPay = (body: { orderGuid: string, bonus: number }): Promise<{ error?: IRequestError }|any> => {
  return useVzFetch(`${httpApiV3}/personal/order/bonus-discount`, {
    method: 'POST',
    body
  })
}

export const orderEditPromocode = (body: { orderGuid: string, promocode: string }): Promise<{ error?: IRequestError }|any> => {
  return useVzFetch(`${httpApiV3}/order/promocode-discount`, {
    method: 'POST',
    body
  })
}

export const getPickupPoint = useMemoize((guid: string) => {
  return useVzFetch<PickupPoint>(`${httpApiV3}/order/pickup-point/${guid}`)
})

export const getPickupPointList = useMemoize((search?: string, city?: Location) => {
  return useVzFetch<{ data: PickupPoint[], meta: Meta }>(
      `${httpApiV3}/order/pickup-point`,
      { params: { page: 1, perPage: 20, locationGuid: city?.guid, search } }
  )
})

export function getPickupPointByBounds(bounds: LngLatBounds, perPage?: number) {
  return useVzFetch<{ data: PickupPoint[], meta: Meta }>(
      `${httpApiV3}/order/pickup-point`,
      { params: { bounds: `[[${bounds[0][0]}, ${bounds[1][1]}], [${bounds[1][0]}, ${bounds[0][1]}]]`, perPage } }
  )
}

export function createOrderDocumentRequest(body: OrderDocumentRequest) {
  return useVzFetch<VZResponse<any>>(
      `${httpApiV3}/order/document`,
      { method: 'post', body }
  )
}

/** @return guid - guid контра, eWorkflow: есть эдо или нет */
export function getContractorGuid(params: { orderNumber?: string|number, inn?: string|number, kpp?: string|number }) {
  return useVzFetch<VZResponse<{ guid: string, eWorkflow: boolean }>>(
      `${httpApiV3}/order/contractor`,
      { method: 'get', params }
  )
}

export const getInventoryList = useMemoize((search: string, params: any) => {
  return useVzFetch<VZResponse<{ data: InventoryItem[], meta: Meta }>>(
      `${httpApiV3}/order/inventory-list`,
      { method: 'GET', params: { search, perPage: 20, noCache: 1 } }
  )
})

export function getLoadingManifest(orderGuid: string) {
  return useVzFetch<VZResponse<LoadingManifest[]>>(
      `${httpApiV3}/order/loading-manifest/${orderGuid}`,
      { method: 'GET' }
  )
}

export function setLoadingManifest(orderGuid: string, data: Array<Partial<LoadingManifest>>) {
  return useVzFetch<VZResponse<LoadingManifest[]>>(
      `${httpApiV3}/order/loading-manifest/${orderGuid}`,
      { method: 'PUT', body: {data} }
  )
}

export const getCargoRegistry = useMemoize((params: { start: string, end: string }) => {
  return useVzFetch<{ url: string }>(`${httpApiV3}/personal/order/cargo-registry`, { method: 'GET', params })
})
